import { Button, Card, Stack, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import DataHandlerTable from "src/components/data-handler/table";
import ParseDate from "src/components/date";
import Map from "src/components/map";
import PaginationButtons from "src/components/pagination";
import Ternary from "src/components/ternary";
import Translate from "src/components/translate";
import { Currency } from "src/components/with-prefix";
import { isMenuActive } from "src/utils/actionProtector";
import ActionDialog from "./ActionDialog";

import DataFilter from "./filter";

import { capitalCase } from "change-case";
import { useTranslation } from "react-i18next";
import useApproval from "./hooks/use-approval";
import useFilter from "./hooks/use-filter";
import ReasonForm from "./reasonForm";

const headers = [
  "financial.deposit_wallet.table.no",
  "Username",
  "Amount",
  "Status",
  "File",
  "Note",
  "financial.deposit_wallet.table.date",
  "financial.payout.admin.request.table.action",
];

const genStatus = (gp, p) => {
  const test = isMenuActive(gp, p);
  return {
    approve: test("approve"),
    reject: test("reject"),
  };
};
const Orders = () => {
  const status = genStatus("global.OrderApproval");
  const methods = useFilter();

  const filter = methods.watch();
  const onFilter = methods.handleSubmit((inputData) => {
    fetchData(1, inputData);
  });
  const { state, fetchData, rowStart, ...rest } = useApproval(filter);
  const { data, ...dataProps } = state;
  const [approved, setApproved] = useState();
  const [rejected, setRejected] = useState();
  const [selectedId, setSelectedId] = useState();
  const handleOpenApproved = (id) => {
    setSelectedId(id);
    setApproved(true);
  };
  const handleCloseApproved = () => {
    setApproved(false);
  };
  const handleOpenRejected = (id) => {
    setSelectedId(id);
    setRejected(true);
  };
  const handleCloseRejected = () => {
    setRejected(false);
  };

  const { t } = useTranslation();
  return (
    <>
      <Card sx={{ pt: 1 }}>
        <DataFilter onFilter={onFilter} methods={methods} />
        <DataHandlerTable
          name="category-table"
          dataProps={{ ...dataProps }}
          headers={headers}
        >
          <Map
            list={data}
            render={(item, i) => (
              <TableRow key={item.id}>
                <TableCell>{i + rowStart}</TableCell>
                <TableCell>{item.user?.username}</TableCell>
                <TableCell>
                  <Currency>{item?.amount}</Currency>
                </TableCell>
                <TableCell>{capitalCase(item?.status || "")}</TableCell>
                <TableCell>
                  <a href={item.file}>
                    <Iconify icon={"humbleicons:download"} />
                  </a>
                </TableCell>
                <TableCell>{item?.note}</TableCell>
                <TableCell>
                  <ParseDate date={item.created_at} />
                </TableCell>

                <TableCell>
                  <Stack direction="row" spacing={2}>
                    <Ternary
                      when={status.approve}
                      then={
                        <Button
                          size="small"
                          disabled={
                            item?.status === "approved" ||
                            item?.status === "rejected"
                          }
                          onClick={() => handleOpenApproved(item.id)}
                          startIcon={<Iconify icon="akar-icons:check" />}
                          variant="contained"
                          name="payout-approve"
                        >
                          <Translate>
                            financial.payout.admin.request.approve
                          </Translate>
                        </Button>
                      }
                    />

                    <Ternary
                      when={status.reject}
                      then={
                        <Button
                          disabled={
                            item?.status === "rejected" ||
                            item?.status === "approved"
                          }
                          size="small"
                          onClick={() => handleOpenRejected(item.id)}
                          startIcon={
                            <Iconify icon="ant-design:delete-outlined" />
                          }
                          color="error"
                          variant="contained"
                          name="payout-reject"
                        >
                          <Translate>global.reject</Translate>
                        </Button>
                      }
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          />
        </DataHandlerTable>
      </Card>
      <PaginationButtons {...rest} />

      <ActionDialog
        fetchData={fetchData}
        title={t("global.approve")}
        statusKyc="approved"
        selectedId={selectedId}
        onClose={handleCloseApproved}
        open={approved}
      />
      <ReasonForm
        fetchData={fetchData}
        title={t("global.reject")}
        selectedId={selectedId}
        onClose={handleCloseRejected}
        open={rejected}
      />
    </>
  );
};

export default Orders;
